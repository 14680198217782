














import {Component, Vue} from 'vue-property-decorator';
import PayoutPolicyModal from '@/components/modals/PayoutPolicyModal.vue';
import {vxm} from '@/store';

@Component({
  components: {PayoutPolicyModal},
})
export default class Finance extends Vue {
  get payoutMethod(): string {
    return vxm.user.data.payoutMethod && vxm.user.data.payoutMethod.name ? vxm.user.data.payoutMethod.name : '';
  }
}
